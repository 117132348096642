<template>
    <div class="icon_wrap" @click="likeItem" style="cursor:pointer">
        <div class="like_img" :class="{'active': isMyLike()}"></div>
        <div class="icon_count" v-if="checkLike()">{{ likeData.im_like }}</div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

export default {
    name: "LikeItemDetailLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeData: {}
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        likeItem() {
            if (!util.isEmpty(this.UserInfo)) {
                EventBus.$emit('like');
            }
        },
        isMyLike() {
            return this.likeData?.Like?.myLike
        },
        checkLike() {
            return !util.isEmpty(this.likeData) && !util.isEmpty(this.likeData.im_like)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
